import React from "react";
import Hero from "../sections/landing1/Hero";

// import CTA from "../sections/landing1/CTA";
import PageWrapper from "../components/PageWrapper";
import JotFormTest from "../components/JotForm";

const IndexPage = () => {
	return (
		<>
			<PageWrapper footerDark>
				<Hero />

				{/* <CTA /> */}
			</PageWrapper>
		</>
	);
};
export default IndexPage;
