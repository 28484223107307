import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box } from "../../components/Core";

import GlobalContext from "../../context/GlobalContext";

import { device } from "../../utils";
import { useStaticQuery } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import { Pages } from "../../components/ContentfulContents/contentfulPages";

import "./Hero.css";

// import copy from "./mockResponse";

const ShapeTopLeft = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	transform: translate(-50%, -50%);
`;

const TopCard = styled(Box)`
	width: 305px;
	box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.shadow}`};
	position: absolute;
	top: 14%;
	left: 0;
	@media ${device.md} {
		left: -13%;
	}
	@media ${device.lg} {
		left: 2%;
	}
`;

const BottomCard = styled(Box)`
	width: 305px;
	box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.shadow}`};
	position: absolute;
	bottom: 12%;
	right: 0;
	@media ${device.md} {
		right: -13%;
	}
	@media ${device.lg} {
		right: -5%;
	}
	@media ${device.xl} {
		right: -33%;
	}
`;

const ImgRight = styled.img`
	max-width: 50%;
	@media ${device.sm} {
		max-width: 60%;
	}
	@media ${device.lg} {
		max-width: 100%;
	}
`;

const getWhitePaper = graphql`
	query {
		hsctWhitePaper: contentfulHsctWhitePaper {
			name
			hsctDocument {
				file {
					url
				}
			}
		}
		allCopy: allContentfulCopy {
			edges {
				node {
					id
					copy {
						childMarkdownRemark {
							html
						}
					}
					title
				}
			}
		}
		sampleImage: allContentfulAsset {
			nodes {
				gatsbyImageData
				id
				title
				description
			}
		}
	}
`;

const Hero = () => {
	const gContext = useContext(GlobalContext);
	const whitePaper = useStaticQuery(getWhitePaper);
	const { homePage } = Pages();

	const {
		node: {
			id: pageId,
			sectionTitle,
			copy: {
				childMarkdownRemark: { html: firstParagraph },
			},
			copy2: {
				childMarkdownRemark: { html: secondPara },
			},
			bulletPoints: {
				childMarkdownRemark: { html: bulletPoints },
			},
		},
	} = homePage[0];

	const {
		hsctWhitePaper: {
			hsctDocument: {
				file: { url },
			},
			id: whitePaperId,
		},
		allCopy: { edges },

		sampleImage: { nodes: images },
	} = whitePaper;

	// const whitePaperCopy = html.replace(/<p>/g, "");
	const pageImage = images.reduce((acc, curr) => {
		if (
			curr.gatsbyImageData !== null &&
			curr.id === "159e806f-9c99-58cb-b5ba-8db96cc3d34e"
		) {
			acc.push(curr);
		}
		return acc;
	}, []);

	// console.log(edges);

	const prospect = edges.filter(
		({ node: { id } }) => id === "9d740f44-ce25-58fc-858e-5e1f47a229a7"
	);

	const whitePaperCopy = edges.filter(
		({ node: { title } }) => title === "Whitepages download copy"
	);

	const openVideoModal = (e) => {
		e.preventDefault();
		gContext.toggleVideoModal();
	};

	return (
		<>
			{/* <!-- Hero Area --> */}
			<Section className="position-relative">
				<div className="pt-5"></div>

				<Container>
					<Row className="justify-content-center align-items-center">
						<Col lg="5" md="8" sm="9" className="order-lg-2">
							<div className="text-center text-lg-right position-relative">
								<div className="img-main"></div>
							</div>
						</Col>
						<div>
							<Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
								{sectionTitle && (
									<>
										<Title variant="card" key={`${pageId}${sectionTitle}`}>
											{sectionTitle}
										</Title>
									</>
								)}
								{firstParagraph && (
									<>
										<span>{ReactHtmlParser(firstParagraph)}</span>
									</>
								)}
								{secondPara && (
									<>
										<span>{ReactHtmlParser(secondPara)}</span>
									</>
								)}
								<Box mt={5} />

								{bulletPoints && <>{ReactHtmlParser(bulletPoints)}</>}
								<Box mt={5} />
								{prospect && (
									<>
										{ReactHtmlParser(
											prospect[0].node.copy.childMarkdownRemark.html
										)}
									</>
								)}
								<div>
									<>
										<a href={url} style={{ border: "1px black" }}>
											CLICK HERE
										</a>{" "}
										{ReactHtmlParser(
											whitePaperCopy[0].node.copy.childMarkdownRemark.html.replace(
												/<p>/g,
												""
											)
										)}{" "}
									</>
									<div className="pt-5"></div>

									{pageImage[0] && (
										<img
											src={pageImage[0]?.gatsbyImageData?.images?.fallback?.src}
											alt={pageImage[0]?.description}
										/>
									)}
								</div>
							</Box>
						</div>
					</Row>
				</Container>
			</Section>
		</>
	);
};

export default Hero;
